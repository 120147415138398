import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
    const store = useMainStore();

    const script = document.createElement("script");
    const storeData = store.currentSite;
    if (!storeData) return false
    if (storeData.settings &&
        storeData.settings.google_analytics &&
        storeData.settings.google_analytics.integrate == true &&
        storeData.settings.google_analytics.app_id
    ) {
        const dataID = storeData.settings.google_analytics.app_id;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${dataID}`;
        script.async = true;
        document.getElementsByTagName("head")[0].appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", dataID);
    }
    if (storeData.country && storeData.country.country_code == "it") {
        const dataIDIt = "AW-16580415221";
        script.src = `https://www.googletagmanager.com/gtag/js?id=${dataIDIt}`;
        script.async = true;
        document.getElementsByTagName("head")[0].appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", dataIDIt);
        gtag("event", "conversion", {
            send_to: "AW-16580415221/mj2NCN2vp7UZEPWdlOI9",
        });
    }
})
